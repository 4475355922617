import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { ReactComponent as Airport } from "./runways.svg";
import { ReactComponent as PlaneNorth } from "./planeNorth.svg";
import { ReactComponent as PlaneSouth } from "./planeSouth.svg";
import { ReactComponent as WindSock } from "./windSock.svg";

import ResponsiveSVG from "../../../ResponsiveSVG";

const RunWays = ({ inView }) => {
  return (
    <div className={cn(s.airport, { [s.inView]: inView })}>
      <ResponsiveSVG ratio={401 / 415}>
        <Airport />
      </ResponsiveSVG>

      <div className={s.windSock}>
        <ResponsiveSVG ratio={401 / 415}>
          <WindSock />
        </ResponsiveSVG>
      </div>

      <div className={cn(s.plane, s.planeOne)}>
        <ResponsiveSVG ratio={401 / 415}>
          <PlaneNorth />
        </ResponsiveSVG>
      </div>

      <div className={cn(s.plane, s.planeThree)}>
        <ResponsiveSVG ratio={401 / 415}>
          <PlaneSouth />
        </ResponsiveSVG>
      </div>

      <div className={cn(s.plane, s.planeTwo)}>
        <ResponsiveSVG ratio={401 / 415}>
          <PlaneNorth />
        </ResponsiveSVG>
        {/* <PlaneNorthTakeoff /> */}
      </div>

      <div className={cn(s.plane, s.planeFour)}>
        <ResponsiveSVG ratio={401 / 415}>
          <PlaneSouth />
        </ResponsiveSVG>
      </div>
    </div>
  );
};

export default RunWays;
