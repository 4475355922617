import React, { memo } from "react";
import s from "./style.module.css";
import Arrow from "../../Arrow";

const Intro = ({ firstId, scroller }) => {
  return (
    <div className={s.intro}>
      <p>Welcome to Heathrow airport. It’s 6:54am.</p>

      <p>
        This aircraft is Shuttle 2 Lima, a British Airways A320. It’s finished
        boarding and ready for take-off.
      </p>

      <p>
        You’re about to see and hear how Air Traffic Controllers guide it
        through the skies to Manchester.
      </p>

      <p>
        <strong>Scroll to begin…</strong>
      </p>

      <p className={s.arrow}>
        <Arrow id={firstId} forceTextHidden />
      </p>
    </div>
  );
};
export default memo(Intro);
