import { useEffect, useRef, useState } from "react";
// import debounce from "lodash.debounce";
import throttle from "lodash.throttle";

export default (isDesktop, heightOfBox) => {
  const ref = useRef();
  const [scrollPercentage, setSoundPercentage] = useState(0);

  useEffect(
    () => {
      if (heightOfBox) {
        const elem = ref.current;

        // Scroll function
        const debouncedFunc = throttle(
          e => {
            const y = elem.scrollTop;
            const percentage = y / heightOfBox;
            setSoundPercentage(Math.max(Math.min(percentage, 0.99999), 0));
          },
          1000 / 15,
          {
            leading: true,
            trailing: true
          }
        );

        elem.addEventListener("scroll", debouncedFunc);

        return () => {
          elem.removeEventListener("scroll", debouncedFunc);
        };
      }
    },
    [heightOfBox, isDesktop]
  );

  return [ref, scrollPercentage];
};
