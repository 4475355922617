import React, { memo } from "react";
import s from "./style.module.css";
import useWhoIsSpeaking from "../../../hooks/useWhoIsSpeaking";

const Trigger = ({ name, from, to, height }) => {
  const { color } = useWhoIsSpeaking("ATC!", name);
  return (
    <div
      className={s.band}
      style={{
        top: `${from * 100}%`,
        height: `${(to - from) * 100}%`,
        background: color
      }}
    />
  );
};
export default memo(Trigger);
