import React from "react";
import s from "./style.module.css";

const Video = ({ id = "302422194", title, visible }) => {
  const src = `https://player.vimeo.com/video/${id}`;

  return (
    <div className={s.wrap}>
      {visible && <iframe src={src} title="Vimeo video" />}
    </div>
  );
};

export default Video;
