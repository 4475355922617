import React, { useRef, useState, useEffect } from "react";
import s from "./style.module.css";
import { Howl } from "howler";

const AudioPlayer = ({ src, name = "Manchester ATIS report" }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [percentage, setPercentage] = useState(-1);
  const ref = useRef();
  const loop = useRef();

  const total = 48.11;

  const updatePercentage = () => {
    const now = ref.current.seek();
    const total = ref.current.duration();
    const percentage = now / total;

    setPercentage(percentage);
    loop.current = requestAnimationFrame(updatePercentage);
  };

  useEffect(
    () => {
      var howl = new Howl({
        src: [src]
      });

      ref.current = howl;
      howl.on("end", () => {
        setIsPlaying(false);
      });
    },
    [src]
  );

  const toggleState = e => {
    e.preventDefault();

    if (!isPlaying) {
      ref.current.play();
      loop.current = requestAnimationFrame(updatePercentage);
    } else {
      cancelAnimationFrame(loop.current);
      ref.current.stop();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <button onClick={toggleState} className={s.wrap}>
      <span className={s.button} onClick={toggleState}>
        {isPlaying ? (
          <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path
                d="m.5 7.8v-7.8m5 7.8v-7.8"
                stroke="#071920"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(5 4)"
              />
            </g>
          </svg>
        ) : (
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
              <path
                d="M8 14.44a.64.64 0 0 1-1-.54V6.1a.64.64 0 0 1 1-.54l6.54 3.9a.62.62 0 0 1 0 1.07L8 14.44z"
                stroke="#071920"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        )}
      </span>

      <div className={s.name}>{name}</div>

      <p className={s.time}>
        0:{("0" + Math.round(ref.current ? ref.current.seek() : 0)).slice(-2)}
        /0:
        {Math.round(total)}
      </p>

      <div className={s.percentage}>
        <span
          className={s.bar}
          style={{
            "--percentage": percentage
          }}
        />
      </div>
    </button>
  );
};

export default AudioPlayer;
