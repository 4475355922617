import React, { useMemo, memo } from "react";
import s from "./style.module.css";
import scaleLinear from "d3-scale/src/linear";
import explainers from "../../data/generated/explainers.json";
import extras from "../../data/generated/extras.json";
// import triggers from "../../data/generated/triggers.json";
import conversations from "../../data/generated/conversation-data.json";
import bands from "../../data/generated/bars.json";

import Conversation from "./Conversation";
import Explainer from "./Explainer";
import Extra from "./Extra";
import Ticks from "./Ticks";
import Intro from "./Intro";
import Band from "./Band";

const Conversations = ({
  min,
  max,
  visible,
  currentMessageId,
  audioEnabled,
  heightOfBox,
  browserHeight
}) => {
  const y = useMemo(
    () =>
      scaleLinear()
        .domain([min, max])
        .range([0, 1]),
    [min, max]
  );

  return (
    <div
      className={s.wrap}
      style={{
        height: `${heightOfBox}px`
      }}
    >
      <Ticks min={min} max={max} y={y} />

      <Intro firstId={conversations[0].id} />

      {conversations.map((data, i) => (
        <div key={data.id}>
          <Conversation
            enabled={visible}
            next={conversations[i + 1]}
            {...data}
            offset={Math.round(y(data.timestamp) * heightOfBox)}
            isHighlighted={i === currentMessageId - 1}
            audioEnabled={audioEnabled && visible}
            browserHeight={browserHeight}
          />
        </div>
      ))}

      {explainers.map((data, i) => (
        <Explainer
          key={i}
          {...data}
          offset={y(data.timestamp)}
          browserHeight={browserHeight}
        />
      ))}

      {extras.map((data, i) => (
        <Extra key={i} {...data} offset={y(data.timestamp)} />
      ))}

      {bands.map((data, i) => (
        <Band key={i} {...data} from={y(data.from)} to={y(data.to)} />
      ))}
    </div>
  );
};

export default memo(Conversations);
