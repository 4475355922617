import React, { useRef, useEffect, useCallback } from "react";
import { Howl } from "howler";
import s from "./style.module.css";

const Sound = ({
  src,
  preload = "auto",
  autoplay,
  playing = false,
  onEnd = () => {},
  enabled
}) => {
  const progress = useRef();
  const sound = useRef();

  const onPlaying = useCallback(percentage => {
    if (progress.current) {
      window.requestAnimationFrame(() => {
        progress.current.setAttribute(
          "style",
          `transform: translate3d(${-100 + percentage * 100}%, 0, 0)`
        );
      });
    }
  }, []);

  useEffect(
    () => {
      var howl = new Howl({
        src: [src]
      });

      sound.current = howl;
      onEnd(0);
    },
    [onEnd, src]
  );

  useEffect(
    () => {
      if (sound.current) {
        if (enabled && playing) {
          // Update function
          const updatePercentage = () => {
            const total = sound.current.duration();
            const now = sound.current.seek();
            const percentage = now / total;
            const roundedPercentage = Math.ceil(percentage * 100) / 100;
            onPlaying(percentage);

            // Still playing so loop
            if (percentage > 0 && roundedPercentage < 0.92) {
              requestAnimationFrame(updatePercentage);
            }

            // Stopped playing at end
            if (roundedPercentage >= 0.92) {
              onPlaying(1);
              onEnd(1);
            }
          };

          sound.current.seek(0.001);
          sound.current.play();
          requestAnimationFrame(updatePercentage);
        } else {
          // Stopped playing halfway
          onPlaying(0);
          onEnd(0);
          if (sound.current.playing()) {
            sound.current.stop();
          }
        }
      }
    },
    [playing, enabled, onPlaying, src, onEnd]
  );

  return (
    <span className={s.sound}>
      <span ref={progress} className={s.progress} />
    </span>
  );
};

export default Sound;
