import React, { memo } from "react";
import s from "./style.module.css";
import { ReactComponent as Tower } from "../../icons/tower.svg";
import { ReactComponent as Plane } from "../../icons/plane.svg";
import { ReactComponent as Controller } from "../../icons/controller.svg";

const Icon = ({ color, icon }) => {
  return (
    <span className={s.icon} style={{ color }}>
      {icon === "tower" && <Tower />}
      {icon === "plane" && <Plane />}
      {icon === "controller" && <Controller />}
    </span>
  );
};

export default memo(Icon);
