import React, { useState, useEffect, lazy, Suspense } from "react";
import useScrollPercentage from "../../hooks/useScrollPercentage";
import usePrevious from "../../hooks/usePrevious";
import useScene from "../../hooks/useScene";
import useAudioMute from "../../hooks/useAudioMute";
import s from "./style.module.css";
import { useMediaPredicate } from "react-media-hook";
import cn from "classnames";

import LandscapeMessage from "../LandscapeMessage";
import HomeScreen from "../HomeScreen";
import StatusBar from "../StatusBar";
import Conclusion from "../Conclusion";
import flightSegments from "../../data/generated/flight-segments.json";
import conversations from "../../data/generated/conversation-data.json";
import triggers from "../../data/generated/triggers.json";
import Conversations from "../Conversations";
import ScrollbarSize from "react-scrollbar-size";
import { useDebounce } from "use-debounce";

const VizPanel = lazy(() => import("../VizPanel"));

const Page = () => {
  const [showHomeScreen, setShowHomeScreen] = useState(true);
  const [scrollBarSize, setScrollBarSize] = useState(0);

  const isDesktop = useMediaPredicate("(min-height: 520px)");
  const [heightOfBox, setHeightOfBox] = useState(1000);
  const [browserHeight, setBrowserHeight] = useState(1000);

  useEffect(
    () => {
      const setHeight = () => {
        const offset = isDesktop ? 123 : 205;
        const h = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );

        setBrowserHeight(h);
        setHeightOfBox(h * offset);
      };

      setHeight();
      window.addEventListener("resize", setHeight);

      return () => {
        window.removeEventListener("resize", setHeight);
      };
    },
    [isDesktop]
  );

  // Get scroll percentage
  const [ref, percentage] = useScrollPercentage(isDesktop, heightOfBox);

  // Get current segment
  const length = flightSegments.length;
  const index = Math.floor(percentage * (length - 1));
  const safeIndex = index;
  const current = flightSegments[safeIndex];

  // Get offsets
  const spread = isDesktop ? 10 : 6;
  const start = flightSegments[0].timestamp + spread;
  const end = flightSegments[length - 1].timestamp + spread;
  const total = end - start;
  const min = flightSegments[0].timestamp;
  const max = flightSegments[flightSegments.length - 1].timestamp;

  const nextTimestamp = start + total * percentage;
  const offset = percentage * total;
  const previousOffset = usePrevious(offset);

  const rounded = Math.round(nextTimestamp);
  const difference = Math.abs(nextTimestamp - rounded);

  const currentTimestamp = difference < 0.01 ? rounded : nextTimestamp;

  // Get blender scene
  const [airport, loadedPercentage] = useScene(
    "scene",
    offset - previousOffset
  );

  const getNearest = (arr, key, goal) => {
    const filtered = arr.filter(item => item[key] <= goal);
    return filtered.length;
  };

  const getNearestItem = (arr, key, goal) => {
    const filtered = arr.filter(item => item[key] <= goal);
    return filtered[filtered.length - 1];
  };

  const radios = triggers.filter(({ type }) => type === "Radio");
  const atcs = triggers.filter(({ type }) => type === "ATC");

  const num = getNearest(conversations, "timestamp", currentTimestamp);
  const [id] = useDebounce(num, 500, { leading: true, trailing: true });

  const nearestAirSector = getNearestItem(atcs, "timestamp", currentTimestamp);
  const airspaceSector = nearestAirSector ? nearestAirSector.to : "Heathrow";

  const nearestRadio = getNearestItem(radios, "timestamp", currentTimestamp);
  const radioFrequency = nearestRadio ? nearestRadio.to : "121.975";

  const { audioEnabled } = useAudioMute();

  return (
    <div>
      <div
        className={cn(s.status, { [s.isHidden]: percentage > 0.995 })}
        style={{
          marginRight: scrollBarSize
        }}
      >
        <StatusBar
          timestamp={currentTimestamp}
          currentMessage={id}
          radioFrequency={radioFrequency}
        />
      </div>

      <div
        className={s.world}
        style={{
          paddingRight: scrollBarSize
        }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <VizPanel
            flight={flightSegments}
            current={current}
            currentMessage={id - 1}
            airport={airport}
            percentage={percentage}
            offset={offset}
            airspaceSector={airspaceSector}
          />
        </Suspense>
      </div>

      <ScrollbarSize
        onLoad={measure => setScrollBarSize(measure.scrollbarWidth)}
        onChange={measure => setScrollBarSize(measure.scrollbarWidth)}
      />
      <LandscapeMessage />
      <HomeScreen
        isHidden={!showHomeScreen}
        hasLoaded={airport}
        loadedPercentage={loadedPercentage}
        hide={() => setShowHomeScreen(false)}
      />

      <div
        className={cn(s.notch, { [s.isHidden]: percentage > 0.995 })}
        style={{ marginRight: scrollBarSize }}
      />
      <div
        className={cn(s.notchRight, { [s.isHidden]: percentage > 0.995 })}
        style={{ marginLeft: -scrollBarSize }}
      />

      <div
        className={cn(s.wrap, { [s.homeScreenVisible]: showHomeScreen })}
        ref={ref}
      >
        <div className={s.conversations}>
          <div className={s.inner}>
            <Conversations
              min={min}
              max={max}
              visible={!showHomeScreen}
              currentMessageId={id}
              audioEnabled={audioEnabled}
              heightOfBox={heightOfBox}
              browserHeight={browserHeight}
            />
          </div>
          <Conclusion browserHeight={browserHeight} />
        </div>
      </div>
    </div>
  );
};

export default Page;
