import React from "react";
import s from "./style.module.css";
import theme from "./style.module.css";
import MuteCheckBox from "../MuteCheckBox";
import Reel from "react-reel";

const StatusBar = ({ timestamp, currentMessage = 0, radioFrequency }) => {
  const time = new Date(timestamp * 1000).toISOString().slice(-13, -5);

  return (
    <div className={s.wrap}>
      <div className={s.item}>
        <strong>{<Reel duration={100} theme={theme} text={time} />}</strong>
      </div>
      <div className={s.item}>
        <strong>
          <Reel theme={theme} text={radioFrequency.replace("MHz", "")} />
        </strong>
        <span>&nbsp;MHz</span>
      </div>

      <div className={s.item}>
        <strong>
          <Reel theme={theme} text={currentMessage.toString()} />
        </strong>
        <strong>/73</strong>
        <span>&nbsp;messages</span>
      </div>

      <MuteCheckBox hideText />
    </div>
  );
};
export default StatusBar;
