import React from "react";
import s from "./style.module.css";
import Page from "./components/Page";
import Div100vh from "react-div-100vh";
import { AudioProvider } from "./components/AudioContext";

const App = () => {
  return (
    <div className={s.app}>
      <AudioProvider>
        <Div100vh>
          <Page />
        </Div100vh>
      </AudioProvider>
    </div>
  );
};

export default App;
