import React, { memo, useMemo } from "react";
import s from "./style.module.css";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import Markdown from "markdown-to-jsx";

import Arrow from "../../Arrow";

import AlphaToZulu from "./AlphaToZulu";
import Weather from "./Weather";
import Runways from "./Runways";
import Altitude from "./Altitude";
import Traffic from "./Traffic";
import Sids from "./Sids";

import { ReactComponent as Wind } from "../../../images/wind.svg";
import ResponsiveSVG from "../../ResponsiveSVG";

const Explainer = ({
  id,
  title,
  sub,
  text,
  timestamp,
  offset,
  next,
  browserHeight
}) => {
  const [ref, inView] = useInView();

  const markdown = useMemo(() => <Markdown>{text}</Markdown>, [text]);

  return (
    <div
      id={id}
      key={title}
      className={s.explainer}
      style={{ top: `${offset * 100}%` }}
    >
      <span
        id={`${id}-jump`}
        className={s.block}
        style={{
          top: -browserHeight * 0.4 + 1
        }}
      />
      <div className={s.inner}>
        <div className={s.container}>
          <div className={s.gridItem}>
            <h2 className={s.title}>{title}</h2>
            <h3 className={s.sub}>{sub}</h3>
            {markdown}
          </div>
          <div className={s.gridItem}>
            <div ref={ref} className={cn({ [s.inView]: inView })}>
              {id === "weather" && <Weather />}

              {id === "sids" && <Sids />}
              {id === "alphaToZulu" && <AlphaToZulu inView={inView} />}
              {id === "wind" && (
                <ResponsiveSVG ratio={226 / 398}>
                  <Wind />
                </ResponsiveSVG>
              )}
              {id === "traffic" && <Traffic inView={inView} />}
              {id === "runways" && <Runways inView={inView} />}
              {id === "altitude" && <Altitude />}
            </div>
          </div>
        </div>
        {next && (
          <div className={s.next}>
            <Arrow id={next} />
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(Explainer);
