import React, { memo } from "react";
import s from "./style.module.css";
import Arrow from "../../Arrow";

const Extra = ({
  type,
  text,
  timestamp,
  offset,
  next,
  hasUsedArror,
  setHasUsedArrow
}) => {
  if (type === "Arrow") {
    return (
      <span className={s.arrow} style={{ top: `${offset * 100}%` }}>
        <Arrow
          id={next}
          hasUsedArror={hasUsedArror}
          setHasUsedArrow={setHasUsedArrow}
        />
      </span>
    );
  }

  return false;
};
export default memo(Extra);
