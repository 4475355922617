import React, { memo } from "react";
import s from "./style.module.css";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import { names } from "../../hooks/useWhoIsSpeaking";

const ctas = [
  {
    url: "https://www.nats.aero/careers/",
    text: "Learn more about careers at NATS"
  },
  {
    url: "https://nats.aero/blog/",
    text:
      "Here our people share their insights, views and expertise on the world of Air Traffic Management"
  },
  {
    url:
      "https://itunes.apple.com/gb/app/nats-airspace-explorer/id1079364261?mt=8",
    text: "Learn more about our world through the Airspace Explorer app"
  }
];

const Conclusion = ({ offset, browserHeight }) => {
  const [ref, inView] = useInView();

  return (
    <div
      id="conclusion"
      ref={ref}
      className={cn(s.conclusion, { [s.inView]: inView })}
      style={{ top: `${offset * 100}%` }}
    >
      <span
        id={"conclusion-jump"}
        className={s.block}
        style={{
          top: -browserHeight * 0.4 + 1
        }}
      />
      <div className={s.container}>
        <div className={s.gridItem}>
          <h2 className={s.title}>Talk me through it</h2>
          <h3 className={s.sub}>
            So what did it take for Air Traffic Control to send Shuttle 2 Lima
            safely from London to Manchester?
          </h3>

          <div className={cn(s.boxes, s.vizzes)}>
            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>73</span>messages in total
              </div>

              <div className={s.footer}>
                <img src={`./images/messages.svg`} alt="" />

                <div className={cn(s.flex, s.flexPadded)}>
                  <div>
                    <strong>41</strong>
                    by Shuttle 2 Lima
                  </div>

                  <div className={s.right}>
                    <strong>32</strong>
                    by controllers
                  </div>
                </div>
              </div>
            </div>

            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>150</span>nautical miles travelled
              </div>

              <img src={`./images/miles-travelled.svg`} alt="" />
            </div>

            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>4.2</span>seconds per average message
              </div>

              <div>
                <img src={`./images/swarm.svg`} alt="" />

                <div className={s.footer}>
                  <div className={s.ticks}>
                    <div className={s.tick}>
                      <span>2</span>
                    </div>
                    <div className={s.tick}>
                      <span>4</span>
                    </div>
                    <div className={s.tick}>
                      <span>6</span>
                    </div>
                    <div className={s.tick}>
                      <span>8</span>
                    </div>
                    <div className={s.tick}>
                      <span>10</span>
                    </div>
                    <div className={s.tick}>
                      <span>12</span>
                    </div>
                  </div>

                  <div className={s.key}>seconds</div>
                </div>
              </div>
            </div>

            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>9</span>air traffic controllers
                involved
              </div>

              <div>
                <img src={`./images/atcs.svg`} alt="" />
                <div className={cn(s.flex, s.flexStreched)}>
                  <span className={s.atcMessages}>1</span>
                  <span className={s.atcMessages}>3</span>
                  <span className={s.atcMessages}>4</span>
                  <span className={s.atcMessages}>6</span>
                  <span className={s.atcMessages}>3</span>
                  <span className={s.atcMessages}>3</span>
                  <span className={s.atcMessages}>7</span>
                  <span className={s.atcMessages}>3</span>
                  <span className={s.atcMessages}>2</span>
                </div>
                <div className={s.key}>messages sent</div>
              </div>
            </div>

            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>5</span>airspace sectors covered
              </div>

              <img
                className={s.full}
                src={`./images/airspace-sectors.svg`}
                alt=""
              />
            </div>

            <div className={s.box}>
              <div className={s.vizTitle}>
                <span className={s.number}>4</span>controller locations
              </div>
              <img
                className={cn(s.full, s.fullVertical)}
                src={`./images/controllers.svg`}
                alt=""
              />
            </div>
          </div>

          <div>
            <h3 className={s.keyTitle}>
              <strong>Air Traffic Controllers</strong>
            </h3>

            <div className={s.controllerKey}>
              {Object.keys(names)
                .filter(name => name !== "Shuttle 2 Lima")
                .map(name => (
                  <div
                    key={name}
                    className={s.keyItem}
                    style={{
                      color: names[name].color
                    }}
                  >
                    <span>{names[name].shortName}</span>
                  </div>
                ))}
              <div />
            </div>
          </div>

          <div className={s.conclusionFooter}>
            <h3 className={s.findOutMore}>Find out more</h3>

            <div className={s.boxes}>
              {ctas.map(({ text, url }) => (
                <a
                  key={url}
                  className={s.cta}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              ))}
            </div>

            <p className={s.more}>
              Discover more stories on{" "}
              <a
                href="https://nats.aero/blog/"
                target="_blank"
                rel="noopener noreferrer"
              >
                our blog
              </a>{" "}
              and follow us on{" "}
              <a
                href="https://www.nats.aero/news/social-media/"
                target="_blank"
                rel="noopener noreferrer"
              >
                social media
              </a>
              .
            </p>

            <div className={s.sources}>
              <p>
                <strong>Notes:</strong>
              </p>

              <ol>
                <li>
                  This flight, Shuttle 2 Lima, took place in September 2017.
                </li>
                <li>
                  Timings have been optimised for a better user journey through
                  the piece.
                </li>
                <li>
                  Radio recordings have been re-recorded based on the real
                  transcript.
                </li>
                <li>
                  The WOBUN departure SID (Standard Instrument Departure) used
                  in the piece is no longer active and has been replaced with a
                  UMLAT 1F departure.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Conclusion);
