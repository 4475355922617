import React, { memo } from "react";
import cn from "classnames";
import s from "./style.module.css";
import useAudioMute from "../../hooks/useAudioMute";

const MuteCheckBox = ({ hideText, disabled }) => {
  const { audioEnabled, toggle } = useAudioMute();
  return (
    <label className={cn(s.muteButton)}>
      {!hideText && <span className={s.text}>Audio:</span>}
      <svg
        className={s.icon}
        width="15"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="#FFF"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M11.126 6.797c1-1.036 1-2.714 0-3.75m1.75 5.625c1.999-2.073 1.999-5.428 0-7.5" />
          <path d="M5.625 1.875L8.125 0.625 8.125 9.375 3.125 6.875 0.625 6.875 0.625 3.125 3.125 3.125" />
        </g>
      </svg>
      <input
        className={s.hidden}
        type={"checkbox"}
        onChange={toggle}
        checked={audioEnabled}
        disabled={disabled}
      />
      <span className={s.checkbox} />
    </label>
  );
};

export default memo(MuteCheckBox);
