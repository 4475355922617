import { useContext } from "react";
import { AudioContext } from "../components/AudioContext";

const useMusicPlayer = () => {
  const [audioEnabled, setEnableAudio] = useContext(AudioContext);

  const mute = () => setEnableAudio(false);
  const unMute = () => setEnableAudio(true);
  const toggle = () => setEnableAudio(!audioEnabled);

  return {
    audioEnabled,
    mute,
    unMute,
    toggle
  };
};

export default useMusicPlayer;
