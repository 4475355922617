import React from "react";
import s from "./style.module.css";

const Arrow = ({ id, scroller, forceTextHidden }) => {
  const isToConclusion = id === "conclusion";
  const message = isToConclusion
    ? "Jump to conclusion"
    : "Jump to next message";

  const jumpTo = id => {
    document
      .getElementById(`${id}-jump`)
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <a
      className={s.wrap}
      onClick={e => {
        e.preventDefault();
        jumpTo(id);
      }}
      href={`#${id}`}
    >
      <svg
        height="22"
        viewBox="0 0 22 22"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <circle
            cx="11"
            cy="11"
            r="9.6"
            stroke="#00bed4"
            fill="#00bed4"
            fillOpacity={0}
          />
          <g
            stroke="#071920"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(7.4 6.2)"
          >
            <path d="m3.60011988 6v-6" />
            <path d="m7.2 5.37228012-3.6 3.6-3.6-3.6" />
          </g>
        </g>
      </svg>
      {!forceTextHidden && <p className={s.text}>{message}</p>}
    </a>
  );
};

export default Arrow;
