import React, { useState, memo } from "react";
import s from "./style.module.css";
import cn from "classnames";
import Sound from "../../Sound";
import Icon from "../../Icon";
import useWhoIsSpeaking from "../../../hooks/useWhoIsSpeaking";
import Arrow from "../../Arrow";

const Conversation = ({
  id,
  conversation,
  time,
  who,
  i,
  airTrafficController,
  explainer,
  isHighlighted,
  offset,
  next,
  enabled,
  audioEnabled,
  browserHeight
}) => {
  const [soundKey, setSoundKey] = useState(1);
  const [soundPercentage, setSoundPercentage] = useState(0);
  const { color, icon, whoIsSpeaking } = useWhoIsSpeaking(
    who,
    airTrafficController
  );

  const replay = () => {
    setSoundKey(soundKey + 1);
  };

  return (
    <div
      id={id}
      style={{
        top: `${offset}px`,
        color: color
      }}
      className={cn(s.conversationWrap, {
        [s.isATC]: who === "ATC",
        [s.isHighlighted]: isHighlighted
      })}
    >
      <span
        id={`${id}-jump`}
        className={s.block}
        style={{
          top: -browserHeight * 0.5 + 1
        }}
      />

      <div className={s.conversation}>
        <small className={s.meta}>
          <span className={s.icon}>
            <Icon color={color} icon={icon} />
          </span>
          <strong className={s.who}>{whoIsSpeaking}</strong>{" "}
          <span className={s.time}>{time}</span>
        </small>
        <p className={s.message}>
          <span>{conversation}</span>

          <button
            disabled={soundPercentage !== 1}
            className={cn(s.replay, {
              [s.replayEnabled]: soundPercentage === 1
            })}
            onClick={replay}
          >
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(.5 .5)" fill="none" fillRule="evenodd">
                <path
                  d="M12.5 8.5a4.002 4.002 0 0 0-6.829-2.829M4.5 8.5a4.002 4.002 0 0 0 6.829 2.829"
                  stroke="#071920"
                  strokeLinecap="round"
                />
                <path
                  stroke="#071920"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.6318 6.0674L5.6718 5.6714 6.0678 3.7114"
                />
                <path
                  stroke="#071920"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.3682 10.9326L11.3282 11.3286 10.9322 13.2886"
                />
              </g>
            </svg>
          </button>

          <span className={s.sound}>
            <Sound
              key={soundKey}
              src={`./audio/${id}.mp3`}
              playing={isHighlighted}
              onEnd={setSoundPercentage}
              enabled={audioEnabled}
            />
          </span>
        </p>
        <p className={s.explainer}>{explainer}</p>
      </div>

      {next && (
        <div className={s.next}>
          <Arrow id={next} />
        </div>
      )}
    </div>
  );
};

export default memo(Conversation);
