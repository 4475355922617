import React, { memo } from "react";
import s from "./style.module.css";
import cn from "classnames";
import AudioPlayer from "../../../AudioPlayer";

const words = [
  { name: "Airfield", value: "Manchester" },
  { name: "ICAO alphabet letter", value: "X-ray" },
  { name: "Time", value: "09:20" },
  { name: "Runway in use", value: "05R" },
  { name: "Transition level", value: "Flight level 65" },
  { name: "Wind speed and direction", value: "320˚/4 kts" },
  { name: "Visibility", value: "8km" },
  { name: "Weather", value: "Slight rain" },
  { name: "Cloud cover", value: "Overcast 1500ft" },
  { name: "Temperature", value: "+11°C" },
  { name: "Dew Point", value: "+10°C" },
  { name: "Pressure at mean sea level (QNH)", value: "1011 " },
  { name: "Threshold QFE", value: "1005" }
];

const Weather = () => {
  return (
    <div>
      <div className={cn(s.words)}>
        <p className={s.title}>ATIS weather report</p>
        {words.map(({ name, value }, i) => (
          <p
            key={i}
            className={s.word}
            style={{ "--delay": i * (1 / words.length) }}
          >
            {name}: <strong>{value}</strong>
          </p>
        ))}
        <p className={s.audio}>
          <AudioPlayer src={"./audio/atis.mp3"} />
        </p>
      </div>
    </div>
  );
};

export default memo(Weather);
