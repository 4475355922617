import React, { useState, memo } from "react";
import s from "./style.module.css";
import cn from "classnames";
import Video from "../../../Video";
import { ReactComponent as Image } from "./sids.svg";
import ResponsiveSVG from "../../../ResponsiveSVG";

const Sids = () => {
  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = e => {
    e.preventDefault();
    setShowVideo(!showVideo);
  };

  return (
    <div className={cn(s.sids, { [s.showVideo]: showVideo })}>
      <p className={s.more}>
        Want to know more?{" "}
        <a
          className={s.switch}
          onClick={toggleVideo}
          href="https://vimeo.com/302422194/f7b8e64b3d"
        >
          Watch this video.
        </a>{" "}
      </p>

      <div className={s.imageWrap}>
        <div className={s.image}>
          <p className={s.textBox}>
            At the end of the SID, or earlier, the controller can take over the
            navigation of the aircraft
          </p>

          <ResponsiveSVG ratio={215 / 289}>
            <Image />
          </ResponsiveSVG>
        </div>

        <div className={s.video}>
          <Video visible={showVideo} />
          <button className={s.close} onClick={toggleVideo}>
            Hide video.
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Sids);
