import React, { memo } from "react";
import s from "./style.module.css";
import cn from "classnames";

const letters = [
  { letter: "A", name: "Alpha" },
  { letter: "B", name: "Bravo" },
  { letter: "C", name: "Charlie" },
  { letter: "D", name: "Delta" },
  { letter: "E", name: "Echo" },
  { letter: "F", name: "Foxtrot" },
  { letter: "G", name: "Golf" },
  { letter: "H", name: "Hotel" },
  { letter: "I", name: "India" },
  { letter: "J", name: "Juliett" },
  { letter: "K", name: "Kilo" },
  { letter: "L", name: "Lima" },
  { letter: "M", name: "Mike" },
  { letter: "N", name: "November" },
  { letter: "O", name: "Oscar" },
  { letter: "P", name: "Papa" },
  { letter: "Q", name: "Quebec" },
  { letter: "R", name: "Romeo" },
  { letter: "S", name: "Sierra" },
  { letter: "T", name: "Tango" },
  { letter: "U", name: "Uniform" },
  { letter: "V", name: "Victor" },
  { letter: "W", name: "Whiskey" },
  { letter: "X", name: "X-ray" },
  { letter: "Y", name: "Yankee" },
  { letter: "Z", name: "Zulu" }
];

const AlphaToZulu = ({ inView }) => (
  <div className={cn(s.letters, { [s.inView]: inView })}>
    {letters.map(({ letter, name }, i) => (
      <div
        key={letter}
        className={s.letter}
        style={{
          "--delay": i * 0.015
        }}
      >
        <p className={s.single}>{letter}</p>
        <p className={s.name}>{name}</p>
      </div>
    ))}
  </div>
);

export default memo(AlphaToZulu);
