import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { ReactComponent as All } from "./altitude.svg";
import ResponsiveSVG from "../../../ResponsiveSVG";

const Altitude = () => {
  return (
    <div className={cn(s.all)}>
      <ResponsiveSVG ratio={407 / 398}>
        <All />
      </ResponsiveSVG>
    </div>
  );
};

export default Altitude;
