import React, { memo } from "react";
import s from "./style.module.css";
import cn from "classnames";

const Ticks = ({ y, min, max }) => {
  const ticks = Array(max - min).fill();

  return (
    <div>
      {ticks.map((_, i) => (
        <span
          className={cn(s.tick, { [s.isMinute]: (i + min) % 10 === 0 })}
          key={i}
          style={{
            top: `${y(i + min) * 100}%`
          }}
        />
      ))}
    </div>
  );
};

export default memo(Ticks);
