import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import { polyfill } from "seamless-scroll-polyfill/dist/es5/seamless.js";

import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import("intersection-observer");

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById("root"));
