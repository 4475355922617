export const names = {
  "Heathrow Delivery Controller": {
    color: "#826CFF",
    icon: "tower",
    shortName: "Heathrow Delivery "
  },
  "Heathrow Ground Controller": {
    color: "#00BF4A",
    icon: "tower",
    shortName: "Heathrow Ground "
  },
  "Heathrow Departures Tower Controller": {
    color: "#DA46AC",
    icon: "tower",
    shortName: "Heathrow Departures Tower "
  },
  "Terminal Control North West Departures Controller": {
    color: "#FFC31E",
    icon: "controller",
    shortName: "Terminal Control North West Departures "
  },
  "Terminal Control Welin Controller": {
    color: "#00BED4",
    icon: "controller",
    shortName: "Terminal Control Welin "
  },
  "Trent Terminal Controller": {
    color: "#FF184D",
    icon: "controller",
    shortName: "Trent"
  },
  "Manchester Approach Radar Controller": {
    color: "#007AFF",
    icon: "tower",
    shortName: "Manchester Approach Radar "
  },
  "Manchester Arrivals Controller": {
    color: "#FFB1E2",
    icon: "tower",
    shortName: "Manchester Arrivals "
  },
  "Manchester Ground Controller": {
    color: "#F35800",
    icon: "tower",
    shortName: "Manchester Ground "
  },
  "Shuttle 2 Lima": { color: "#E7EEF0", icon: "plane" }
};

export default (who, airTrafficController) => {
  const whoIsSpeaking =
    who === "Pilot" ? "Shuttle 2 Lima" : airTrafficController;

  const cleaned = whoIsSpeaking
    .replace(" has left", "")
    .replace(" has joined", "");

  return {
    ...names[cleaned],
    whoIsSpeaking
  };
};
