import React, { useState } from "react";

const AudioContext = React.createContext([{}, false]);

const AudioProvider = props => {
  const [audioEnabled, setEnableAudio] = useState(true);
  return (
    <AudioContext.Provider value={[audioEnabled, setEnableAudio]}>
      {props.children}
    </AudioContext.Provider>
  );
};

export { AudioContext, AudioProvider };
