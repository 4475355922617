import { useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "../helpers/DRACOLoader";
import { AnimationMixer } from "three";

const useScene = (name, offset) => {
  const [loadedPercentage, setLoadingPercentage] = useState(0);
  const [model, setModel] = useState(false);
  const [mixer, setMixer] = useState(false);

  useEffect(
    () => {
      DRACOLoader.setDecoderPath(`./draco/gltf/`);
      DRACOLoader.setDecoderConfig({ type: "js" });
      DRACOLoader.getDecoderModule();

      const loader = new GLTFLoader();
      loader.setDRACOLoader(new DRACOLoader());
      loader.load(
        `./objects/${name}.glb`,
        model => {
          const mixer = new AnimationMixer(model.scene);
          model.animations.forEach(function(clip) {
            mixer.clipAction(clip).play();
          });

          setMixer(mixer);
          setLoadingPercentage(100);
          setModel(model);
        },
        xhr => {
          setLoadingPercentage(Math.round((xhr.loaded / 857760) * 100));
        }
      );
    },
    [name]
  );

  useEffect(
    () => {
      if (mixer) {
        if (offset !== 0) {
          mixer.update(offset);
        }
      }
    },
    [mixer, offset]
  );

  return [model, loadedPercentage];
};

export default useScene;
