import React, { memo } from "react";
import s from "./style.module.css";

const ResponsiveSVG = ({ children, ratio }) => {
  return (
    <div
      className={s.wrapper}
      style={{
        paddingBottom: `${ratio * 100}%`
      }}
    >
      {children}
    </div>
  );
};

export default memo(ResponsiveSVG);
