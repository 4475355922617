import React from "react";
import s from "./style.module.css";

const LandscapeMessage = () => {
  return (
    <div className={s.message}>
      <p>
        Please rotate your device vertically or resize your browser to view.
      </p>
    </div>
  );
};

export default LandscapeMessage;
