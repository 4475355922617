import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { ReactComponent as Pattern } from "./traffic.svg";
import { ReactComponent as Plane } from "./plane.svg";
import { ReactComponent as Plane2 } from "./plane2.svg";
import { ReactComponent as Plane3 } from "./plane3.svg";
import { ReactComponent as Plane4 } from "./plane4.svg";

import ResponsiveSVG from "../../../ResponsiveSVG";

const Traffic = ({ inView }) => {
  return (
    <div className={cn(s.traffic, { [s.inView]: inView })}>
      <div className={s.bg}>
        <ResponsiveSVG ratio={264 / 422}>
          <Pattern />
        </ResponsiveSVG>
      </div>

      <div className={s.planeWrap}>
        <div className={s.plane}>
          <ResponsiveSVG ratio={1}>
            <Plane />
          </ResponsiveSVG>
        </div>
      </div>

      <div className={cn(s.planeWrap, s.second)}>
        <div className={cn(s.plane, s.second)}>
          <ResponsiveSVG ratio={1}>
            <Plane2 />
          </ResponsiveSVG>
        </div>
      </div>

      <div className={cn(s.planeWrap, s.third)}>
        <div className={cn(s.plane, s.third)}>
          <ResponsiveSVG ratio={1}>
            <Plane3 />
          </ResponsiveSVG>
        </div>
      </div>

      <div className={cn(s.planeWrap, s.forth)}>
        <div className={cn(s.plane, s.forth)}>
          <ResponsiveSVG ratio={1}>
            <Plane4 />
          </ResponsiveSVG>
        </div>
      </div>
    </div>
  );
};

export default Traffic;
